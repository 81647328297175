import { TokenList } from '@uniswap/token-lists'
import mainnet from './tokens/mainnet.json'
import ropsten from './tokens/ropsten.json'
import rinkeby from './tokens/rinkeby.json'
import goerli from './tokens/goerli.json'
import kovan from './tokens/kovan.json'
import ganache from './tokens/ganache'

const VERSION = '0.0.1'

function buildList() {
  const parsed = VERSION.split('.')
  return {
    name: 'Safepay Default List',
    timestamp: new Date().toISOString(),
    version: {
      major: +parsed[0],
      minor: +parsed[1],
      patch: +parsed[2],
    },
    tags: {},
    logoURI: 'ipfs://QmNa8mQkrNKp1WEEeGjFezDmDeodkWRevGFN8JCV7b4Xir',
    keywords: ['safepay', 'default'],
    tokens: [...mainnet, ...ropsten, ...goerli, ...kovan, ...rinkeby, ...ganache]
      // sort them by symbol for easy readability
      .sort((t1, t2) => {
        if (t1.chainId === t2.chainId) {
          return t1.symbol.toLowerCase() < t2.symbol.toLowerCase() ? -1 : 1
        }
        return t1.chainId < t2.chainId ? -1 : 1
      }),
  }
}

export function getTokenListByName(name: string): TokenList {
  return buildList()
}
