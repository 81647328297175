import React from 'react'
import styled from 'styled-components'

const AdvancedDetailsFooter = styled.div<{ show: boolean }>`
  padding-top: calc(16px + 2rem);
  padding-bottom: 20px;
  margin-top: -2rem;
  width: 100%;
  max-width: 400px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: ${({ theme }) => theme.text2};
  background-color: ${({ theme }) => theme.advancedBG};
  border: 1px solid ${({ theme }) => theme.bg5};
  z-index: -1;

  transform: ${({ show }) => (show ? 'translateY(0%)' : 'translateY(-100%)')};
  transition: transform 300ms ease-in-out;
`

export default function AdvancedDetailsDropdown({ show, children }: { show?: boolean; children: React.ReactNode }) {
  return <AdvancedDetailsFooter show={show}>{children}</AdvancedDetailsFooter>
}
